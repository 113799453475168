/**
 * API Service
 */
  const axios = require('axios').default;
  const _ = require('lodash');
  const stringify = require('fast-json-stable-stringify');

  // get from .env.${NODE_ENV} at build time
  const apiBaseUrl = process.env.GATSBY_API_URL;    // was: 'http://localhost:3000/v1'  or  'https://api.bendable.com/v1';
  if (!apiBaseUrl) {
    console.log('********************************************************************');
    console.log('*************** ERROR: apiService missing apiBaseUrl ***************');
    console.log('********************************************************************');
    console.log('Available process.env: ', process.env);
  } else {
    console.log('apiService apiBaseUrl: ', apiBaseUrl);
  }
 
  let verboseLogging = true;

  const callDefinitions = {
    createEmailSignup: {
      pathTemplate: apiBaseUrl + '/marketing/emailSignups',
      method: 'POST'
    },
    createSalesInquiry: {
      pathTemplate: apiBaseUrl + '/marketing/salesInquiries',
      method: 'POST'
    },
  };
 
  const objectToQueryString = (obj) => {
    let str = [];
    for(const p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  };

/**
 * Make a generic HTTP GET
 * @param  {[type]}   endpoint [description]
 * @param  {Function} callback [description]
 * @return {[type]}            [description]
 */
  const httpGet = (endpoint, callback) => {
  if (verboseLogging) console.log('api.httpGet() endpoint: ', endpoint);
  axios.get(endpoint)
    .then(function(response) {
      if (verboseLogging) console.log('api.httpGet() response, status: ', response);
      return callback(null, response);
    })
    .catch(function(error) {
      console.log('api.httpGet() error; error, status: ', error);
      return callback(error);
    });
  };

  /**
   * Make a generic HTTP POST
   * @param  {[type]}   endpoint     [description]
   * @param  {[type]}   postData     [description]
   * @param  {Function} callback     [description]
   * @return {[type]}                [description]
   */
  const httpPost = (endpoint, postData, callback) => {
    if (verboseLogging) console.log('api.httpPost(): endpoint, postData: ', endpoint, postData);
    axios.post(endpoint, postData)
      .then(function(response) {
        if (verboseLogging) console.log('api.httpPost() success; response, status: ', response);
        return callback(null, response);
      })
      .catch(function(error) {
        console.log('api.httpPost() error; error, status: ', error);
        return callback(error);
      });
  };

  /**
   * Helper function to POST a Javascript object as a serialized JSON string
   *
   * @param  {[type]}   endpoint      [description]
   * @param  {[type]}   objectForJson [description]
   * @param  {Function} callback      [description]
   * @return {[type]}                 [description]
   */
  const httpPostJson = (endpoint, objectForJson, callback) => {
    let postData = {
      'json': stringify(objectForJson)
    };
    return httpPost(endpoint, postData, callback);
  };

  /**
   * Make a generic HTTP PUT
   * @param  {[type]}   endpoint [description]
   * @param  {[type]}   postData [description]
   * @param  {Function} callback [description]
   * @return {[type]}            [description]
   */
  const httpPut = (endpoint, postData, callback) => {
    if (verboseLogging) console.log('api.httpPut(): endpoint, postData: ', endpoint, postData);
    axios.put(endpoint, postData)
      .then(function(response) {
        if (verboseLogging) console.log('api.httpPut() response, status: ', response);
        return callback(null, response);
      })
      .catch(function(error) {
        console.log('api.httpPut() error; error, status: ', error);
        return callback(error);
      });
  };

  /**
   * Helper function to PUT a Javascript object as a serialized JSON string
   *
   * @param  {[type]}   endpoint      [description]
   * @param  {[type]}   objectForJson [description]
   * @param  {Function} callback      [description]
   * @return {[type]}                 [description]
   */
  const httpPutJson = (endpoint, objectForJson, callback) => {
    let postData = {
      'json': stringify(objectForJson)
    };
    return httpPut(endpoint, postData, callback);
  };

  /**
   * Make a generic HTTP DELETE
   * @param  {[type]}   endpoint [description]
   * @param  {Function} callback [description]
   * @return {[type]}            [description]
   */
  const httpDelete = (endpoint, callback) => {
    if (verboseLogging) console.log('api.httpDelete(): endpoint: ', endpoint);
    axios.delete(endpoint)
      .then(function(response) {
        if (verboseLogging) console.log('api.httpDelete() response, status: ', response);
        return callback(null, response);
      })
      .catch(function(error) {
        console.log('api.httpDelete() error; error, status: ', error);
        return callback(error);
      });
  };  

 
  /**
  * Generalized API call
  *
  * @param  {[type]}   apiKey             [description]
  * @param  {[type]}   substitutionParams [description]
  * @param  {[type]}   objOrJson           [description]
  * @param  {Function} callback           [description]
  * @return {[type]}                      [description]
  *
  * Returned object is augmented with the original _apiKey, _substitutionParams, 
  * and _jsonData
  */
 
  const callApi = (apiKey, substitutionParams, objOrJson, callback) => {
    // helper for GETs and DELETEs that don't have objOrJson
    if (_.isFunction(objOrJson)) {
      callback = objOrJson;
      objOrJson = null;
    }

    // get the call definition
    let callDefinition = callDefinitions[apiKey];
    if  (!callDefinition) return callback({ error: 'No API call found for ' + apiKey });

    let pathTemplate = callDefinition.pathTemplate;
    let regex = /(?:^|\W):(\w+)(?!\w)/g;
    let regexTokens = [];

    let tempArray;
    while ((tempArray = regex.exec(pathTemplate)) !== null) {
      regexTokens.push(tempArray[1]);
    }

    // sanitize substitutionParams
    _.forOwn(substitutionParams, function(value, key, obj) {
      // substitutionParams values must be strings
      if (_.isNumber(value)) {
        obj[key] = '' + value;
      }
      // values with strings with must be URLencoded or we won't match routes when /, etc is present
      if (_.isString(value)) {
        obj[key] = encodeURIComponent(value);
      }
    });

    // console.log('apiService.callApi() substitutionParams: ', substitutionParams);

    let path = pathTemplate;
    for (let i = 0; i < regexTokens.length; i++) {
      let regexToken = regexTokens[i];
      if (!substitutionParams[regexToken]) {
        console.log('apiService.callApi() error; missing required path regexToken for apiKey, regexToken: ', apiKey, regexToken);
        return callback({ error: 'Missing required path regexToken for ' + apiKey + ' (' + regexToken + ')' });
      }
      path = path.replace((':' + regexToken), substitutionParams[regexToken]);
    }
    // console.log('callApi() path: ', path);

    let queryString;

    if (callDefinition.method === 'GET') {
      queryString = objectToQueryString(objOrJson);
      if (queryString) {
        path = path + '?' + queryString;
      }
      httpGet(path, function(err, response) {
        response = response || {};
        if (verboseLogging) console.log('apiService.callApi(); GET; response: ', response);
        response._apiKey = apiKey;
        response._substitutionParams = substitutionParams;
        return callback(err, response);
      });
    }

    if (callDefinition.method === 'POST') {
      httpPost(path, objOrJson, function(err, response) {
        response = response || {};
        if (verboseLogging) console.log('apiService.callApi(); POST; response: ', response);
        response._apiKey = apiKey;
        response._substitutionParams = substitutionParams;
        return callback(err, response);
      });
    }

    if (callDefinition.method === 'POST-JSON') {
      httpPostJson(path, objOrJson, function(err, response) {
        response = response || {};
        if (verboseLogging) console.log('apiService.callApi(); POST; response: ', response);
        response._apiKey = apiKey;
        response._substitutionParams = substitutionParams;
        response._jsonData = objOrJson;
        return callback(err, response);
      });
    }

    if (callDefinition.method === 'PUT') {
      httpPut(path, objOrJson, function(err, response) {
        response = response || {};
        if (verboseLogging) console.log('apiService.callApi(); PUT; response: ', response);
        response._apiKey = apiKey;
        response._substitutionParams = substitutionParams;
        return callback(err, response);
      });
    }

    if (callDefinition.method === 'DELETE') {
      queryString = objectToQueryString(objOrJson);
      if (queryString) {
        path = path + '?' + queryString;
      }
      httpDelete(path, function(err, response) {
        response = response || {};
        if (verboseLogging) console.log('apiService.callApi(); DELETE; response: ', response);
        response._apiKey = apiKey;
        response._substitutionParams = substitutionParams;
        return callback(err, response);
      });
    }

  };
 
export default callApi;
 